import React from 'react';
import {Container, Row, Col, Image} from 'react-bootstrap';
import '../styles/pages/projects.css';
import Fade from 'react-reveal/Fade';
import translate from '../i18n/translations/translate';

function Projects() {
        return (
            <React.Fragment>
                <Container>

                <h1 className="projectsHeader">{translate("projects.projects-header")}</h1>

                <Row className="product">
                    <Col lg={5}>
                        <Fade><Image className="projectsImage d-flex justify-content-center" src={require('../styles/img/app.jpg')} /></Fade>
                    </Col>
                    <Col lg={7}>
                        <Fade>
                            <h5 className="productTitle">{translate("projects.project-title-first")}</h5>
                            <p className="productDescription">{translate("projects.project-text-first")}</p>
                        </Fade>
                    </Col>
                </Row>
               
                
                <Row className="product">
                    <Col lg={5}>
                        <Fade><Image className="projectsImage d-flex justify-content-center" src={require('../styles/img/scoreboard_1.jpeg')} /></Fade>
                    </Col>
                    <Col lg={7}>
                        <Fade>
                            <h5 className="productTitle">{translate("projects.project-title-second")}</h5>
                            <p className="productDescription">{translate("projects.project-text-second")}</p>
                        </Fade>
                    </Col>
                </Row>

                <Row className="product">
                    <Col lg={5}>
                        {/* <Fade><Image className="projectsImage d-flex justify-content-center" src={require('../styles/img/iphone.png')} /></Fade> */}
                    </Col>
                    <Col lg={7}>
                        <Fade>
                            <h5 className="productTitle">{translate("projects.project-title-third")}</h5>
                            <p className="productDescription">{translate("projects.project-text-third")}</p>
                        </Fade>
                    </Col>
                </Row>

                </Container>

            </React.Fragment>
        )
}


export default Projects
