import React from 'react';
import {Container} from 'react-bootstrap';

function CookieNotice() {
        return (
            <div>  
                
                <Container>
                    <h1 className="aboutHeader">Cookie notice</h1>

                    <h4 style={cookiesHeaders}>INTRODUCTION</h4>
                    <p style={cookiesContent}>
                    
                    [Business Entity Name] (“we” or “us” or “our”) may use cookies, web beacons, tracking pixels, and other tracking technologies when you visit our website [Name of Website.com], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience. 
                    We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification. 
                    You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted. 
                    This Cookie Policy was created using Termly’s Cookie Consent Manager.
                    </p>

                    <h4 style={cookiesHeaders}>USE OF COOKIES</h4>
                    <p style={cookiesContent}>
                    A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience
                    </p>
                
                    <h4 style={cookiesHeaders}>Types of cookies</h4>
                    <p style={cookiesContent}>The following types of cookies may be used when you visit the Site:</p>

                    <h6 style={cookiesSubHeading}>Analytics Cookies</h6>
                    <p style={cookiesContent}>Analytics cookies monitor how users reached the Site, and how they interact with and move around once on the Site. These cookies let us know what features on the Site are working the best and what features on the Site can be improved. </p>

                    <h6 style={cookiesSubHeading}>Our Cookies</h6>
                    <p style={cookiesContent}>Our cookies are “first-party cookies”, and can be either permanent or temporary. These are necessary cookies, without which the Site won't work properly or be able to provide certain features and functionalities. Some of these may be manually disabled in your browser, but may affect the functionality of the Site.</p>

                    <h6 style={cookiesSubHeading}>Security Cookies</h6>
                    <p style={cookiesContent}>Security cookies help identify and prevent security risks. We use these cookies to authenticate users and protect user data from unauthorized parties.</p>

                    <h6 style={cookiesSubHeading}>Third-Party Cookies</h6>
                    <p style={cookiesContent}>Third-party cookies may be place on your computer when you visit the Site by companies that run certain services we offer. These cookies allow the third parties to gather and track certain information about you. These cookies can be manually disabled in your browser. </p>

                </Container>
            </div>
        )
}

const cookiesHeaders = {
    fontSize: '50px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    color: '#fbbc33',
    paddingTop: '25px'
}

const cookiesSubHeading = {
    fontSize: '36px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    color: '#fbbc33',
    paddingTop: '25px'
}

const cookiesContent = {
    color: 'white',
    fontFamily: 'Raleway',
    fontSize: '24px'
}

export default CookieNotice
