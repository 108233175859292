import React, { Fragment } from 'react';
import { IntlProvider } from 'react-intl';

import { LOCALES } from './locales';
import translations from './translations'
import flatten from 'flat';

const Provider = ({children, locale = LOCALES.LATVIAN}) => {
    return <IntlProvider 
        locale={locale} 
        textComponent={Fragment} 
        messages={flatten(translations[locale])}
        >
            {children}
    </IntlProvider>
}

export default Provider;