import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap';
import translate from '../i18n/translations/translate';

import '../styles/pages/about.css';
import '../../src/App.css';

function About() {
        return (
            <React.Fragment>

                <Container>
                <h1 className="aboutHeader">{translate("about.about-header")}</h1>      
                    <Row className="aboutSections">
                        <Col sm={12} md={6} className="order-2 order-sm-2">
                            <Image fluid className="aboutImages" src={require('../styles/img/test3.png')} />
                        </Col>
                        <Col sm={12} md={6} className="order-1 order-sm-1">
                            <h4 className="titleHeader">{translate("about.about-head-first")}</h4>
                            <p className="aboutText">
                                {translate("about.about-text-first")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="aboutSections">
                        <Col sm={12} md={6} className="order-md-2 order-sm-1">
                            <h4 className="titleHeader">{translate("about.about-head-second")}</h4>
                            <p className="aboutText">
                                {translate("about.about-text-second")}
                            </p>
                        </Col>
                        <Col sm={12} md={6} className="order-md-1 order-sm-2">
                            <Image fluid className="aboutImages" src={require('../styles/img/test1.png')} />
                        </Col>
                    </Row>
                    <Row className="aboutSections">
                        <Col sm={12} md={6} className="order-2 order-sm-2">
                            <Image fluid className="aboutImages" src={require('../styles/img/test2.png')} />
                        </Col>
                        <Col sm={12} md={6} className="order-1 order-sm-1">
                            <h4 className="titleHeader">{translate("about.about-head-third")}</h4>
                            <p className="aboutText">
                                {translate("about.about-text-third")}
                            </p>
                        </Col>
                    </Row>

                </Container>
            </React.Fragment>
        )
    
}

export default About
