import { LOCALES } from '../locales';

export default {
    [LOCALES.ENGLISH]: {
        'nav': {
            'about': 'About Us',
            'services': 'Services',
            'projects': 'Projects',
            'contact': 'Contacts',
            'privacy': 'Privacy policy',
            'cookie': 'Cookie policy'
        },
        'home': {
            'main-quote': 'Building custom solutions for your business.',
            'head-first': 'WHO ARE WE',
            'caption-first': 'Software development company from Latvia which creates systems for event organization and other solutions which are needed for clients.',
            'head-second': 'WHAT WE DO',
            'caption-second': 'Explore more what services we can offer for your project.',
            'head-third': 'WHAT WE MADE',
            'caption-third': 'Open to take a look on what we have worked with previously.',
            'technologies': 'Technologies we use',
            'clients': 'Our clients and partners'
        },
        'about': {
            'about-header': 'About our company.',
            'about-head-first': 'Who are we',
            'about-text-first': 'We are a small software development company ro Riga, Latvia which has made solutions and applications for some of the biggest and well known sports events such as World Championship in ice hockey and Spengler cup.',
            'about-head-second': 'How it all started',
            'about-text-second': 'We began to work in the ice hockey championship which was held in Riga in 2006. Now we are partners for the International Ice Hockey Federation since championship in Denmark, in the year 2018. We were building mobile applications and internal systems for the tournament.',
            'about-head-third': 'How it all evolved',
            'about-text-third': 'After a successful partnership in 2018., we continued our work in 2019. IIHF Worlds in Slovakia. Despite the global pandemic in 2020 which stopped almost every single event around the globe, it gave us some inspiration to begin building our own products. Together with that, we also help other businesses by developing IT solutions for them.'
        },
        'services': {
            'services-header': 'What are we doing.',
            'dev-ops': 'DevOps',
            'dev-ops-text': 'In the development stage, we`re using DevOps methodology, which is needed to improve the speed of delivery, lower the costs and to ensure that the solution is high quality. DevOps improves collaboration between stakeholders and development team, and advances the development stage itself by automating processes and allowing more frequent deployments.',
            'web-dev': 'Web applications',
            'web-dev-text': 'From a single-page website to a complex web application for your business. We can build any solution that you need for different kinds of industries - landing pages, e-commerce sites, systems for sports events, you name it. We have worked with different industries, so we are not afraid to try something new.',
            'app-dev': 'App development',
            'app-dev-text': 'Smartphone usage and mobile app downloads are increasing every year. Now almost everything can be accessed from a mobile device. Your business probably wants the same kind of flexibility, so we offer application development for mobile phones and tablets. We are building apps for Android and iOS systems.',
            'design': 'UI/UX DESIGN',
            'design-text': 'To make system, website, mobile app, etc. to be usable, it must be easy to use, good looking and most importantly - to be able to do all necessary functions that client needs. We are creating design not only based on newest trends, but closely with a client and using user tests to gain feedback, which is the most important part of improving user experience.',
            'testing': 'QA & TESTING',
            'testing-text': 'To ensure the quality of a system, it must be tested. During the development stage, every project is being tested thoroughly, starting from unit tests till full end-to-end integration tests. To speed up the testing process and to be able to check the system more frequently, cases are also being automated.',
            'it-security': 'IT security audit',
            'it-security-text': 'We are offering IT system audit, which includes internal and external context evaluation and evaluation of the company itself - we will take a look into internal structure of the organization, processes, information flow, infrastructure analysis, etc. After the audit, recommendations for security systems and processes will be given.',
            'business-analysis': 'Business Analysis',
            'business-analysis-text': 'Business analysts will work closely with the client to gather requirements, research how it can be build, what will be the impact and design how the solution is going to look and work. This is the person who translates business needs to the development team and vice versa.'
        },
        'projects': {
            'projects-header': 'Explore use cases.',
            'project-title-first': 'IIHF Official App',
            'project-text-first': 'Official application for International Ice Hockey Federation tournament games, statistics and news. Available for Android and iOS smartphones and tablets.',
            'project-title-second': 'IIHF Scoreboard software WM2019',
            'project-text-second': 'Software for arena jumbotron and other screens, which uses custom graphics and statistics from information systems to show information about score, game data and player statistics.',
            'project-title-third': 'Video Referee system',
            'project-text-third': 'System, used in hockey games for reviewing different game situations like penalties, goals, etc. It uses filmed material from different spots around the ice rink.'
        },
        'contacts': {
            'contacts-header': 'Contact us.',
            'find-us': 'Where to find us',
            'requisites': 'Requisites',
            'registration': 'Registration no.',
            'VATnr': 'VAT Reg. no.',
            'legalAddress': 'Legal address',
            'bankAcc': 'AS Citadele bank, account no.'
        },
        'footer': {
            'legal': 'Privacy',
            'connect': 'Social',
            'contact': 'Contact',
            'rights': 'All rights reserved'
        }
    }
}