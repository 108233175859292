import React, { useContext } from 'react';
import {Navbar, Nav, Image} from 'react-bootstrap';
import { Link } from 'react-router-dom'; 

import { I18nProvider, LOCALES } from '../../i18n';
import translate from '../../i18n/translations/translate';

import './Header.css';

import { AppContext } from '../../providers/context';
import { saveToStorage } from '../../utils/localStorage';


function Header()  {
        const { state, dispatch } = useContext(AppContext);
        const setLanguage = siteLanguage => {
        dispatch({type: 'setLanguage', siteLanguage});
            saveToStorage('siteLanguage', siteLanguage);
        }

        return (
            <I18nProvider locale={state.siteLanguage}>
                <React.Fragment>
                    <header>
                        <Navbar expand="lg" className="navbar-style" variant="dark">
                                <Navbar.Brand><Image className="projectsImage" src={require('../../styles/img/logo2.png')} /></Navbar.Brand>
                                <Navbar.Toggle className="navbar-hamburger" aria-controls="header-navbar" />
                                <Navbar.Collapse id="header-navbar">
                                    <Nav className="ml-auto">
                                        <Nav><Link className="linkStyle" to="about">{translate("nav.about")}</Link></Nav>
                                        <Nav><Link className="linkStyle" to="services">{translate("nav.services")}</Link></Nav>
                                        <Nav><Link className="linkStyle" to="projects">{translate("nav.projects")}</Link></Nav>
                                        <Nav><Link className="linkStyle" to="contact">{translate("nav.contact")}</Link></Nav>
                                        <Nav><Link className="linkStyle" onClick={() => setLanguage(LOCALES.LATVIAN)}><img src={require('../../styles/img/latvia-flag-icon-32.png')} alt=""></img></Link></Nav>
                                        <Nav><Link className="linkStyle" onClick={() => setLanguage(LOCALES.ENGLISH)}><img src={require('../../styles/img/united-kingdom-flag-xs.png')} height="16px" alt=""></img></Link></Nav>
                                    </Nav>
                                </Navbar.Collapse>
                        </Navbar>
                    </header>
                </React.Fragment>
             </I18nProvider>
        )
}


export default Header;