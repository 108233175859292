import { LOCALES } from '../locales';

export default {
    [LOCALES.LATVIAN]: {
        'nav': {
            'about': 'Par mums',
            'services': 'Pakalpojumi',
            'projects': 'Projekti',
            'contact': 'Kontakti',
            'privacy': 'Privātuma politika',
            'cookie': 'Sīkdatnes'
        },
        'home': {
            'main-quote': 'Veidojam Jūsu biznesam pielāgotus risinājumus.',
            'head-first': 'Kas mēs esam',
            'caption-first': 'Programmatūras izstrādes kompānija no Latvijas, kura veido sistēmas pasākumu organizēšanai, kā arī citus risinājumus, atkarībā no klientu vēlmēm.',
            'head-second': 'Ko mēs darām',
            'caption-second': 'Apskatiet tuvāk, kādus pakalpojumus mēs varam piedāvāt Jūsu idejas realizēšanai.',
            'head-third': 'Ko esam paveikuši',
            'caption-third': 'Uzziniet vairāk par mūsu realizētajiem projektiem.',
            'technologies': 'Mūsu izmantotās tehnoloģijas',
            'clients': 'Klienti un partneri'
        },
        'about': {
            'about-header': 'Par mums.',
            'about-head-first': 'Kas mēs esam',
            'about-text-first': 'Mēs esam neliela programmatūras izstrādes kompānija no Rīgas, Latvijas, kas ir veidojusi programmatūru un aplikācijas dažiem no lielākajiem un zināmākajiem sporta pasākumiem pasaulē kā Pasaules Čempionāts hokejā un Špenglera kauss.',
            'about-head-second': 'Kā viss sākās',
            'about-text-second': 'Mēs sākām savu darbību jau 2006. gadā, kad Latvijā tika organizēts Pasaules čempionāts hokejā. Šobrīd esam starptautiskās hokeja federācijas partneri kopš 2018. gada čempionāta Dānijā. Pamatā veidojam iekšējās sistēmas un aplikācijas priekš hokeja turnīriem, sākot ar čempionāta aplikāciju, beidzot ar informācijas sistēmām. ',
            'about-head-third': 'Kā viss turpinājās',
            'about-text-third': 'Ar savām prasmēm turpinājām palīdzēt arī 2019. gada čempionātā Slovākijā. Neskatoties uz globālo pandēmiju, kas 2020. gadā apstādināja visas pasaules pasākumu plānus, sākām skatīties arī ārpus sporta industrijas un izmēģinājām spēkus savu produktu veidošanā.'
        },
        'services': {
            'services-header': 'Mūsu pakalpojumi.',
            'dev-ops': 'DevOps',
            'dev-ops-text': 'Izstrādes posmā mēs izmantojam DevOps metodoloģiju, kas nepieciešama, lai risinājuma piegādes būtu ātrākas, izdevīgākas un izveidotais risinājums būtu augstas kvalitātes. DevOps uzlabo gan sadarbību starp klientu un izstrādes komandu, gan arī uzlabo pašu izstrādes posmu, automatizējot dažādus procesus un ļaujot regulāri piegādāt jaunākās izmaiņas.',
            'web-dev': 'Mājas lapu izstrāde',
            'web-dev-text': 'No vienkāršām portfolio lapām līdz sarežģītām sistēmām priekš Jūsu biznesa darbības. Mums ir pieredze visdažādāko risinājumu veidošanā, visdažādākajām industrijām - portfolio lapas, internetveikali, aplikācijas sporta turnīriem, sporta pasākumu iekšējās sistēmas, utt. Tā kā esam strādājuši ar dažādām industrijām, mēs esam gatavi veidot risinājumus jebkuram. ',
            'app-dev': 'Aplikāciju izstrāde',
            'app-dev-text': 'Viedtālruņu un lejuplādēto mobilo aplikāciju skaits strauji palielinās katru gadu. Šobrīd visu informāciju var atrast, izmantojot mobilo ierīci. Jūsu bizness noteikti vēlas, lai to ātri pamana un Jūsu pakalpojumus var ērti sasniegt, tāpēc mēs piedāvājam aplikāciju izstrādi priekš mobilajām ierīcēm un planšetdatoriem. Mūsu komanda veido aplikācijas priekš Android un iOS operētājsistēmām.',
            'design': 'UI/UX dizaina izstrāde',
            'design-text': 'Lai sistēmu, mājaslapu vai jebkādu citu risinājumu varētu veiksmīgi izmantot, tai jābūt vienkāršai, vizuāli pievilcīgai un pats galvenais, jāspēj izpildīt visas klientam nepieciešamās darbības. Dizainu veidojam ne tikai pēc jaunākajām tendencēm, bet cieši strādājot kopā ar klientu un testējot to ar lietotājiem, iegūstam atgriezenisko saiti, kas ir svarīgākā informācija lietotāju pieredzes uzlabošanai.',
            'testing': 'Testēšana',
            'testing-text': 'Lai nodrošinātu sistēmas darbības kvalitāti, tai ir nepieciešama testēšana. Katra risinājuma izstrādē, tā tiek pilnībā pārbaudīta, sākot no vienību testiem izstrādes laikā, līdz pilnai integrāciju testēšanai. Lai paātrinātu testēšanas procesu un to varētu darīt biežāt, testi tiek arī automatizēti, ļaujot pārbaudīt risinājumu jebkurā brīdī.',
            'it-security': 'IT drošības audits',
            'it-security-text': 'Mēs piedāvājam IT sistēmu audita pakalpojumu, kas iekļauj iekšējo un ārējo riska izvērtēšanu un uzņēmuma novērtēšanu, apskatot organizācijas struktūru, iekšējos procesus, informācjias plūsmu, infrastruktūras apskati (inventārs, izstrādes procesi, programmatūra, u.tml.). Pēc audita, izstrādājam rekomendācijas drošības sistēmas ieviešanai.',
            'business-analysis': 'Biznesa analīze',
            'business-analysis-text': 'Biznesa analītiķis strādās kopā ar klientu, lai apkopotu prasības, izpētītu, kā to uzbūvēt un kāds būs izmaiņu ietekme uz jau esošo risinājumu, kā arī veidos functionālo dizainu tam, kā tam ir jāstrādā un jāizskatās, procesu veidā. Šis ir tas cilvēks, kas spēj iztulkot “biznesa valodu” izstrādātāju komandai un otrādi.'
        },
        'projects': {
            'projects-header': 'Mūsu projekti.',
            'project-title-first': 'IIHF Oficiālā aplikācija',
            'project-text-first': 'Oficiālā Starptautiskās hokeja federācijas turnīru lietotne, kas satur informāciju par visiem hokeja čempionātiem, spēļu statistiku un informāciju par to norisi, un ziņas.',
            'project-title-second': 'IIHF Rezultātu tablo lietotne',
            'project-text-second': 'Sistēma priekš arēnas videokuba un citiem ekrāniem, kas izmanto turnīram veidotās grafikas un informāciju par spēles gaitu, lai informatīvā un izklaidējošā veidā parādītu rezultātu un statistiku par spēles gaitu un spēlētājiem.',
            'project-title-third': 'Videotiesnešu sistēma',
            'project-text-third': 'Lietotne, kas tiek izmantota, lai pārskatītu dažādas situācijas hokeja spēles laikā, piemēram, iespējamie noteikumu pārkāpumi, vārtu gūšanas momenti, utt. Tā izmanto nofilmēto materiālu no kamerām, kas ir izvietotas dažādās vietās apkārt ledus laukumam.'
        },
        'contacts': {
            'contacts-header': 'Sazinieties ar mums.',
            'find-us': 'Kā mūs atrast',
            'requisites': 'Rekvizīti',
            'registration': 'Reģistrācijas nr.',
            'VATnr': 'PVN maksātāja nr.',
            'legalAddress': 'Juridiskā adrese',
            'bankAcc': 'AS Citadele banka, konta nr.'
        },
        'footer': {
            'legal': 'Privātums',
            'connect': 'Sociālie tīkli',
            'contact': 'Kontakti',
            'rights': 'Visas tiesības aizsargātas'
        }

    }
}