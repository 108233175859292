import React from 'react'
import {Container, Row, Col, Image} from 'react-bootstrap/';
import { Link } from 'react-router-dom';
import '../styles/pages/homepage.css';
import '../App.css';
import '../components/layout/Header';
import translate from '../i18n/translations/translate';
import { techPics } from '../styles/img/technologies-pics';
import { clientsPics } from '../styles/img/clients-pics';

function Homepage () {
    
        return (
            <div>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <h1 className="homePageHeader">{translate("home.main-quote")}</h1>
                        </Col>
                        <Col sm={12} md={6}>
                            <Image alt="homepage img" src={require('../styles/img/homepage.png')} className="homepageImg" fluid></Image>
                        </Col>
                    </Row>
                    
                    <Row className="section">
                            <Col className="sectionIcons" sm={12} md={6}>
                                <i className="fas fa-users"></i>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="linkContainer">
                                    <Link className="sectionHeaders" to="about">{translate("home.head-first")}</Link>
                                </div>
                                <p className="introText">{translate("home.caption-first")}</p>
                            </Col>
                        </Row>
                    <Row className="section">
                            <Col className="sectionIcons" sm={12} md={6}>
                                <i className="fas fa-code"></i>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="linkContainer">
                                    <Link className="sectionHeaders" to="services">{translate("home.head-second")}</Link>
                                </div>
                                <p className="introText">{translate("home.caption-second")}</p>    
                            </Col>
                        </Row>
                    <Row className="section">
                            <Col className="sectionIcons" sm={12} md={6}>
                                <i className="fas fa-tasks"></i>
                            </Col>
                            <Col sm={12} md={6}>
                                <div className="linkContainer">
                                    <Link className="sectionHeaders" to="projects">{translate("home.head-third")}</Link>
                                </div>
                                <p className="introText">{translate("home.caption-third")}</p>
                            </Col>
                        </Row>

                    <div className="section">
                        <h4 className="headerNotLinked">{translate("home.technologies")}</h4>
                        <Row>
                            {techPics.map((tPic, index) => (
                            <Col sm={12} md={6} lg={4} key={index} >
                                <div className="picsSection">
                                    <Image className="techPics d-flex align-items-center" src={tPic.imgLink} alt={tPic.altText} fluid/>
                                    <div className="image__overlay image__overlay--primary">
                                        <h5 className="techName">{tPic.name}</h5>
                                    </div>
                                </div>
                            </Col>
                            ))}
                        </Row>
                    </div>

                    <div className="section">
                        <h4 className="headerNotLinked">{translate("home.clients")}</h4>
                        <Row>
                            {clientsPics.map((cPic, index) => (
                            <Col sm={12} md={6} lg={4} key={index}>
                                <div className="picsSection">
                                    <Image className="techPics d-flex align-items-center" src={cPic.imgLink} alt={cPic.altText} fluid/>
                                    <div className="image__overlay image__overlay--primary">
                                        <a href={cPic.url} target="_blank" rel="noopener noreferrer" className="cPicLink">{cPic.name}</a>
                                    </div>
                                </div>
                            </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </div>    
        )
}

export default Homepage
