import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';
import '../styles/pages/contact.css';
import translate from '../i18n/translations/translate';

function Contact() {

    return (
        <div>
            
            <Container>
            <h1 className="contactsHeader">{translate("contacts.contacts-header")}</h1>
                <Row>
                    <Col>
                        <h3 className="contactDetHeaders">{translate("contacts.find-us")}</h3>
                        <p className="contactDetails"><i class="iconsSocial fas fa-at"></i>info@01event.org</p>
                        <p className="contactDetails"><i class="iconsSocial fas fa-building"></i> Balasta dambis 80A, Rīga, Latvija</p>
                        <p className="contactDetails"> <i class="iconsSocial fas fa-phone-alt"></i> +371 27824500</p>
                    </Col>
                    <Col sm={6}>
                        <h3 className="contactDetHeaders">{translate("contacts.requisites")}</h3>
                        <p className="contactDetails">SIA "MG56"</p>
                        <p className="contactDetails"><strong>{translate("contacts.registration")}</strong> 40003783532</p>
                        <p className="contactDetails"><strong>{translate("contacts.VATnr")}</strong> LV40003783532</p>
                        <p className="contactDetails"><strong>{translate("contacts.legalAddress")}</strong> Jūrmala, Ventas iela 1, LV-2015</p>
                        <p className="contactDetails"><strong>{translate("contacts.bankAcc")}</strong> LV85PARX0021023580001</p>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}


export default Contact
