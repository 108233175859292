import React from 'react'
import {Container, Row, Col} from 'react-bootstrap';

import translate from '../i18n/translations/translate';

import '../styles/pages/services.css'
import Fade from 'react-reveal/Fade';

function Services() {

        return (
            <React.Fragment>

                <Container>
                    <h1 className="servicesHeader">{translate("services.services-header")}</h1>

                    <Row className="servicesRows">
                        <Col sm={12} md={8} className="order-2 order-sm-2">
                            <Fade left>
                                <h4 className="titleHeader">{translate("services.dev-ops")}</h4>
                                <p className="servicesText">{translate("services.dev-ops-text")}</p>
                            </Fade>
                        </Col>
                        <Col className="iconsStyle order-1 order-sm-1" sm={12} md={4}>
                            <Fade right>
                            <i className="fas fa-infinity"></i>
                            </Fade>
                        </Col>
                    </Row>
                    
                    <Row className="servicesRows">
                        <Col className="iconsStyle order-md-2 order-sm-1" sm={12} md={4} >
                            <Fade left>
                            <i className="fas fa-laptop-code"></i>
                            </Fade>
                        </Col>
                        <Col sm={12} md={8} className="order-md-1 order-sm-2">
                            <Fade right>
                            <h4 className="titleHeader">{translate("services.web-dev")}</h4>
                            <p className="servicesText">{translate("services.web-dev-text")}</p>
                            </Fade>
                        </Col>
                    </Row>
                        <Row className="servicesRows">
                            <Col sm={12} md={8} className="order-2 order-sm-2">
                                <Fade left>
                                    <h4 className="titleHeader">{translate("services.app-dev")}</h4>
                                    <p className="servicesText">{translate("services.app-dev-text")}</p>
                                </Fade>
                            </Col>
                            <Col className="iconsStyle order-1 order-sm-1" sm={12} md={4}>
                                <Fade right>
                                <i className="fas fa-mobile-alt"></i>
                                </Fade>
                            </Col>
                        </Row>
                    
                    <Row className="servicesRows">
                        <Col className="iconsStyle order-md-2 order-sm-1" sm={12} md={4}>
                            <Fade left>
                            <i className="fas fa-pen"></i>
                            </Fade>
                        </Col>
                        <Col sm={12} md={8} className="order-md-1 order-sm-2">
                            <Fade right>
                                <h4 className="titleHeader">{translate("services.design")}</h4>
                                <p className="servicesText">{translate("services.design-text")}</p>
                            </Fade>
                        </Col>
                    </Row>
                
                    <Row className="servicesRows">
                        <Col sm={12} md={8} className="order-2 order-sm-2">
                            <Fade left>
                                <h4 className="titleHeader">{translate("services.testing")}</h4>
                                <p className="servicesText">{translate("services.testing-text")}</p>
                            </Fade>
                        </Col>
                        <Col className="iconsStyle order-1 order-sm-1" sm={12} md={4}>
                            <Fade right>
                                <i className="far fa-user"></i>
                            </Fade>
                        </Col>
                    </Row>
                
                    <Row className="servicesRows">
                        <Col className="iconsStyle order-md-2 order-sm-1" sm={12} md={4}>
                            <Fade left>
                                <i className="fas fa-lock"></i>
                            </Fade>
                        </Col>
                        <Col sm={12} md={8} className="order-md-1 order-sm-2">
                            <Fade right>
                                <h4 className="titleHeader">{translate("services.it-security")}</h4>
                                <p className="servicesText">{translate("services.it-security-text")}</p>
                            </Fade>    
                        </Col>
                    </Row>
                
                    <Row className="servicesRows">
                        <Col sm={12} md={8} className="order-2 order-sm-2">
                            <Fade left>
                                <h4 className="titleHeader">{translate("services.business-analysis")}</h4>
                                <p className="servicesText">{translate("services.business-analysis-text")}</p>
                            </Fade>
                        </Col>
                        <Col className="iconsStyle order-1 order-sm-1" sm={12} md={4}>
                            <Fade right>
                                <i className="fas fa-user-tie"></i>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
}


export default Services
