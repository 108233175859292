export const clientsPics = [
    {
        "imgLink": require("./clients/iihf.jpg"),
        "altText": "iihf",
        "name": "IIHF",
        "url": "https://www.iihf.com"
    },
    {
        "imgLink": require("./clients/dinamo.png"),
        "altText": "dinamo riga",
        "name": "Dinamo Riga",
        "url": "https://www.dinamoriga.eu"
    },
    {
        "imgLink": require("./clients/hkriga.png"),
        "altText": "hk riga",
        "name": "HK Riga",
        "url": "https://www.hkr.lv/"
    },
    {
        "imgLink": require("./clients/hkolimp.png"),
        "altText": "hk olimp",
        "name": "HK Olimp",
        "url": "https://www.facebook.com/rigasolimp/"
    },
    {
        "imgLink": require("./clients/jokerit.png"),
        "altText": "jokerit",
        "name": "HC Jokerit Helsinki",
        "url": "https://www.jokerit.com/en"
    },
    {
        "imgLink": require("./clients/klredstar.png"),
        "altText": "klredstar",
        "name": "HC Kunlun Red Star",
        "url": "http://www.hcredstar.com/krs/en/KRS_home.html"
    },
    {
        "imgLink": require("./clients/smnetworks.png"),
        "altText": "smnetworks",
        "name": "Santa Monica Networks",
        "url": "https://www.smn.lv/"
    },
    {
        "imgLink": require("./clients/logo.png"),
        "altText": "gostack",
        "name": "GOStack",
        "url": "https://www.gostack.eu"
    },
]