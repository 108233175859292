import React from 'react';
import {Row, Col, Container} from 'react-bootstrap';
import { Link } from 'react-router-dom'; 
import translate from '../../i18n/translations/translate';

import './Footer.css';

function Footer() {
    return (
        <React.Fragment>  
            <footer className="footerStyle">
                <Container>
                    <Row>
                        <Col sm={4}>
                            <h5 className="footerHeader">01 Event</h5>
                            <ul>
                                <li><Link className="footerLinks" to="about">{translate("nav.about")}</Link></li>
                                <li><Link className="footerLinks" to="services">{translate("nav.services")}</Link></li>
                                <li><Link className="footerLinks" to="projects">{translate("nav.projects")}</Link></li>
                                <li><Link className="footerLinks" to="contact">{translate("nav.contact")}</Link></li>
                            </ul>
                            
                        </Col>
                        <Col sm={4}>
                            <h5 className="footerHeader">{translate("footer.legal")}</h5>
                            <ul>
                                <li><Link className="footerLinks" to="privacy-notice">{translate("nav.privacy")}</Link></li>
                                <li><Link className="footerLinks" to="cookie-notice">{translate("nav.cookie")}</Link></li>
                            </ul>
                        </Col>
                        {/* <Col sm={3}>
                            <h5 className="footerHeader">{translate("footer.connect")}</h5>
                            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="iconsSocial fab fa-linkedin"></i></a>
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="iconsSocial fab fa-facebook-square"></i></a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><i className="iconsSocial fab fa-twitter-square"></i></a>
                        </Col> */}
                        <Col sm={4}>
                            <h5 className="footerHeader">{translate("footer.contact")}</h5>
                            <p>Balasta dambis 80A <br/>
                                Rīga <br />
                                LV-1048
                                <br />
                                <br/>
                                +371 27824500
                                <br />
                                <br />
                                info@01event.org</p>
                        </Col>
                    </Row>
                </Container>
                <Row>
                    <Col>
                        <p className="copywrite">
                            ©2020 01Event, {translate("footer.rights")}
                        </p>
                    </Col> 
                </Row>
            </footer>
        </React.Fragment>    
    )
}

export default Footer;