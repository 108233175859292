import React, { useContext } from 'react';
import { BrowserRouter, Route, Link } from 'react-router-dom';

import { I18nProvider, LOCALES } from './i18n';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {Navbar, Nav, Image} from 'react-bootstrap';
import './components/layout/Header.css';
import translate from './i18n/translations/translate';

import { AppContext } from './providers/context';
import { saveToStorage } from './utils/localStorage';
import Homepage from './pages/Homepage';
import About from './pages/About';
import Services from './pages/Services';
import Projects from './pages/Projects';
import Contact from './pages/Contact';
import Privacynotice from './pages/Privacynotice';
import Cookienotice from './pages/Cookienotice';

// import Header from './components/layout/Header';
import Footer from './components/layout/Footer';


function App() {
  const { state, dispatch } = useContext(AppContext);
  const setLanguage = siteLanguage => {
    dispatch({type: 'setLanguage', siteLanguage});
		saveToStorage('siteLanguage', siteLanguage);
  }
  
  return (
    <I18nProvider locale={state.siteLanguage}>
    <BrowserRouter>
      <div className="App">
        <div className="page-container">
          <div className="wrapper">
            {/* <Header /> */} 
            <header>
                <Navbar expand="lg" className="navbar-style" variant="dark">
                  <Navbar.Brand><Link to="/"><Image id="logo" src={require('./styles/img/logo_yellow.png')} /></Link></Navbar.Brand>
                  <Navbar.Toggle className="navbar-hamburger" aria-controls="header-navbar" />
                  <Navbar.Collapse id="header-navbar">
                      <Nav className="ml-auto">
                          <Nav><Link className="linkStyle" to="about">{translate("nav.about")}</Link></Nav>
                          <Nav><Link className="linkStyle" to="services">{translate("nav.services")}</Link></Nav>
                          <Nav><Link className="linkStyle" to="projects">{translate("nav.projects")}</Link></Nav>
                          <Nav><Link className="linkStyle" to="contact">{translate("nav.contact")}</Link></Nav>
                          <Nav><Link className="linkStyle" onClick={() => setLanguage(LOCALES.LATVIAN)}><img src={require('./styles/img/latvia-flag-icon-32.png')} alt=""></img></Link></Nav>
                          <Nav><Link className="linkStyle" onClick={() => setLanguage(LOCALES.ENGLISH)}><img src={require('./styles/img/united-kingdom-flag-xs.png')} height="16px" alt=""></img></Link></Nav>
                      </Nav>
                  </Navbar.Collapse>
                </Navbar>
            </header>
              <Route exact path="/" component={Homepage}/>
              <Route path="/about" component={About} />
              <Route path="/services" component={Services} />
              <Route path="/projects" component={Projects} />
              <Route path="/contact" component={Contact} />
              <Route path="/privacy-notice" component={Privacynotice} />
              <Route path="/cookie-notice" component={Cookienotice} />
          </div>
          <Footer />
        </div>
      </div>
    </BrowserRouter>
    </I18nProvider>
  );
}

export default App;
