export const techPics = [
    {
        "imgLink": require("./technologies-pics/dotnet.png"),
        "altText": "dot net",
        "name": ".NET"
    },
    {
        "imgLink": require("./technologies-pics/amazon.png"),
        "altText": "amazon",
        "name": "Amazon Web Services"
    },
    {
        "imgLink": require("./technologies-pics/android.png"),
        "altText": "android",
        "name": "Android"
    },
    {
        "imgLink": require("./technologies-pics/ios.png"),
        "altText": "ios",
        "name": "iOS"
    },
    {
        "imgLink": require("./technologies-pics/docker.png"),
        "altText": "docker",
        "name": "Docker"
    },
    {
        "imgLink": require("./technologies-pics/express.png"),
        "altText": "express",
        "name": "Express"
    },
    {
        "imgLink": require("./technologies-pics/nodejs.png"),
        "altText": "nodejs",
        "name": "NodeJS"
    },
    {
        "imgLink": require("./technologies-pics/react.png"),
        "altText": "react",
        "name": "React JS"
    },
    {
        "imgLink": require("./technologies-pics/vuejs.png"),
        "altText": "vuejs",
        "name": "Vue JS"
    },
]